<template>
  <div>
    <h3 class="empty-ph is-size-6">
      {{ str.additional.pay_i_title }}
    </h3>
    <div
      class="wet-input wet-input-with-label"
      :class="$v.form.iban.$error ? 'wet-input-error' : ''"
    >
      <input
        id="wet-input-iban"
        type="text"
        :placeholder="str.additional.iban_plh"
        v-model.trim="$v.form.iban.$model"
      >
      <label for="wet-input-iban">
        {{ str.additional.iban_plh }}
      </label>
    </div>
    <div v-if="$v.form.iban.$error" class="small text-primary mt-n2 mb-3 mx-3">
      {{ str.additional.error_iban }}
    </div>
    <label
      id="wet-bank-consent-chkbx-label"
      class="checkbox is-size-7 mb-3"
      for="wet-bank-consent-chkbx"
    >
      <strong class="empty-ph">
        {{ str.settings.consent_message }}
      </strong>
      <input
        type="checkbox"
        id="wet-bank-consent-chkbx"
        class=""
        v-model="$v.form.consentChkbx.$model"
      >
      <span id="wet-bank-consent-chkbx-span"></span>
    </label>
    <div v-if="$v.form.consentChkbx.$error" class="small text-primary mt-n2 mb-3 mx-3">
      {{ str.settings.error_payment_checkbox }}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  iban,
} from '@/common/validations';
import LogService from '@/services/LogService';

export default {
  props: {
    validated: Boolean,
    formApi: Object,
    str: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        iban: '',
        consentChkbx: true,
      },
    };
  },
  computed: {
    ...mapState(['strings']),
  },
  watch: {
    form: {
      deep: true,
      handler(v) {
        this.$emit('form', v);
        this.$v.$touch();
        this.$emit('update:validated', !this.$v.$anyError);
      },
    },
    formApi: {
      immediate: true,
      handler(v) {
        LogService.log('formApi updated', v);
        this.form = { ...v };
      },
    },
  },
  validations() {
    return {
      form: {
        iban,
        consentChkbx: {
          checked: (val) => val,
        },
      },
    };
  },
};
</script>
