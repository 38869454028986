<template>
  <div>
    <nameAndPhone
      :error-first-name="str.commonApp.error_firstname"
      :error-last-name="str.commonApp.error_lastname"
      :first-name-place-holder="str.additional.first_name_plh"
      :last-name-place-holder="str.additional.last_name_plh"
      :phone-place-holder="str.additional.phone_plh"
      :phone-initial="formApi.phone"
      :first-name-initial="formApi.firstname"
      :last-name-initial="formApi.lastname"
      :phone-prefix="formApi.phoneCode"
      :personal-data-title="str.additional.persnl_i_title"
      :phone-error="str.commonApp.error_phone"
      @change-firstname="updateFirstname"
      @change-lastname="updateLastname"
      @change-phone="updatePhone"
      @change-phone-code="updatePhoneCode"
      ref="nameAndPhone"
    ></nameAndPhone>
    <div
      class="wet-input wet-input-with-label"
      :class="$v.form.email.$error ? 'wet-input-error' : ''"
    >
      <input
        id="wet-input-email"
        type="email"
        :placeholder="str.additional.email_plh"
        v-model.trim="$v.form.email.$model"
        :disabled="true"
      >
      <label for="wet-input-email">
        {{ str.additional.email_plh }}
      </label>
    </div>
    <div v-if="$v.form.email.$error" class="small text-primary mt-n2 mb-3 mx-3">
      {{ str.additional.error_email }}
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import {
  required,
  email,
} from 'vuelidate/lib/validators';
import LogService from '@/services/LogService';

export default {
  mixins: [common],
  props: {
    validated: Boolean,
    formApi: Object,
    str: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        firstname: this.formApi.firstname,
        lastname: this.formApi.lastname,
        phone: this.formApi.phone,
        phoneCode: this.formApi.phoneCode,
        email: this.formApi.email,
        codeSelectOpen: false,
      },
    };
  },
  components: {
    nameAndPhone: () => import('@/components/vacation-buy/nameAndPhone.vue'),
  },
  methods: {
    updateFirstname(firstname) {
      this.form.firstname = firstname;
      LogService.log('personal component: change firstname', firstname);
    },
    updateLastname(lastname) {
      this.form.lastname = lastname;
      LogService.log('personal component: change lastname', lastname);
    },
    updatePhone(phone) {
      this.form.phone = phone;
      LogService.log('personal component: change phone', phone);
    },
    updatePhoneCode(phoneCode) {
      this.form.phoneCode = phoneCode;
      LogService.log('personal component: change phoneCode', phoneCode);
    },
  },
  watch: {
    form: {
      handler(newForm) {
        LogService.log('watch form: changed', newForm);
        this.$emit('form', newForm);
        this.$v.$touch();
        if (this.$refs.nameAndPhone) {
          this.$refs.nameAndPhone.$v.$touch();
          this.$emit('update:validated', !this.$v.$anyError && !this.$refs.nameAndPhone.$v.$anyError);
        }
      },
      deep: true,
    },
    // firstname(newFirstname) {
    //   this.$emit('change-firstname', newFirstname);
    // },
    // lastname(newLastname) {
    //   this.$emit('change-lastname', newLastname);
    // },
    // phoneCode(newPhoneCode) {
    //   this.$emit('change-phone-code', newPhoneCode);
    // },
    // phone(newPhone) {
    //   this.$emit('change-phone', newPhone);
    // },
    // email(newEmail) {
    //   this.$emit('change-email', newEmail);
    // },
    formApi: {
      handler(v) {
        LogService.log('formApi updated', v);
        LogService.log('form API changed', v);
        if (this.$refs.nameAndPhone) {
          if (v.firstname) {
            this.$refs.nameAndPhone.firstname = v.firstname;
          }
          if (v.lastname) {
            this.$refs.nameAndPhone.lastname = v.lastname;
          }
          if (v.phone) {
            this.$refs.nameAndPhone.phone = v.phone;
          }
          if (v.phoneCode) {
            this.$refs.nameAndPhone.phoneCode = v.phoneCode.toString();
          }
          this.form = { ...v };
          LogService.log('updated form data in personal component', this.form);
        }

        if (v.email) {
          this.form.email = v.email;
        }
      },
    },
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
      },
    };
  },
};
</script>
