<template>
  <div>
    <h3 class="empty-ph is-size-6">
      {{strings.additional && strings.additional.company_name_title}}
    </h3>
    <input-text
      elevated hideDetails="auto" class="mb-3"
      id="wet-input-company"
      :label="strings.additional && strings.additional.company_name_plh"
      v-model.trim="$v.form.companyName.$model"
      :errors="$v.form.companyName.$error && strings.additional
        ? [strings.additional.error_company] : []"
    />
    <h3 class="empty-ph is-size-6 mt-4 mt-sm-5">
      Registration address
    </h3>
    <places-component
      :address="formApi.registration"
      class="my-3"
      :invalid.sync="registrationInvalid"
      :id="'wet-input-reg-street-places'"
      :placeholder="strings.additional && strings.additional.str_and_house_plh"
      :searchCountries="searchCountries"
      @change-address="(v) => $set(form, 'registration', {
        ...v,
        country: v.country,
      })"
    />
    <div v-if="registrationInvalid" class="small text-primary mt-n2 mb-3 mx-3">
      {{ strings.additional && strings.additional.error_registraion_address }}
    </div>
    <div class="row no-gutters">
      <div class="col-5 pr-3">
        <input-text
          elevated hideDetails disabled class="mb-3"
          id="wet-input-reg-zip"
          :label="strings.additional && strings.additional.zip_code_plh"
          :value="form.registration.zip"
        />
      </div>
      <div class="col-7">
        <input-text
          elevated hideDetails disabled class="mb-3"
          id="wet-input-reg-city"
          :label="strings.additional && strings.additional.city_plh"
          :value="form.registration.city"
        />
      </div>
    </div>
    <input-text
      elevated hideDetails disabled class="mb-3"
      id="registration-country"
      :label="strings.additional && strings.additional.country_plh"
      :value="registrationCountry"
    />
    <div class="wet-note mt-2 mb-3">
      {{strings.additional && strings.additional.country_desc}}
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  companyName,
} from '@/common/validations';
import placesComponent from '@/components/placesComponent.vue';
import countriesMap from '@/constants/countries';
import inputText from '@/components/ui/inputTextUi.vue';

export default {
  components: {
    placesComponent,
    inputText,
  },
  props: {
    validated: Boolean,
    formApi: Object,
  },
  data() {
    return {
      form: {
        companyName: '',
        registration: {},
      },
      registrationInvalid: false,
      searchCountries: [process.env.VUE_APP_COUNTRY_CODE_SALE] || ['DE'],
    };
  },
  computed: {
    ...mapState(['strings']),
    registrationCountry() {
      const countryName = countriesMap[this.form.registration?.country?.toLowerCase()] || '';
      return countryName ? countryName[0].toUpperCase() + countryName.slice(1) : '';
    },
  },
  watch: {
    form: {
      deep: true,
      handler(v) {
        this.$emit('form', v);
        this.$v.$touch();
        this.$emit('update:validated', !this.$v.$anyError);
      },
    },
    formApi: {
      immediate: true,
      handler(v) {
        this.form = this.$lodash.cloneDeep(v);
      },
    },
  },
  validations() {
    return {
      form: {
        companyName,
      },
    };
  },
};
</script>
