<template>
  <div class="d-flex flex-column">
    <transition name="page">
      <template v-if="loading.length === 0">
        <div v-if="!breakpoint.md" class="border-y-between">
          <template v-for="(item, i) in menu">
            <accordeon-component
              v-if="'component' in item"
              :key="i"
              @update:open="toggle(i)"
              :open="opened === i"
            >
              <template v-slot:header>
                <div class="p-3">
                  {{item.title}}
                </div>
              </template>
              <template v-slot:content>
                <div class="p-3">
                  <component
                    :validated.sync="validated"
                    :formApi="formApi"
                    :str="str"
                    :is="item.component"
                    @form="updateForm"
                  ></component>
                </div>
              </template>
            </accordeon-component>
            <router-link
              v-else-if="!('component' in item) && item.href"
              :to="item.href"
              :key="i"
              class="wet-accordeon-header d-block"
            >
              <div class="p-3">
                {{item.title}}
              </div>
            </router-link>
            <div
              v-else
              :key="i"
              class="wet-accordeon-header"
              @click="item.event"
            >
              <div class="p-3">
                {{item.title}}
              </div>
            </div>
          </template>
        </div>
        <div v-else class="row no-gutters flex-grow-1">
          <div class="" style="width:300px;">
            <settingsComponent class="border-right border-gray-light h-100" />
          </div>
          <div class="col">
            <div class="wet-section">
              <div class="container">
                <transition name="page" mode="out-in">
                  <component
                    :validated.sync="validated"
                    @form="updateForm"
                    :formApi="formApi"
                    :str="str"
                    :is="settingsContent"
                  ></component>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>
<script>
import personalComponent from '@/components/settings/personalSettings.vue';
import companyComponent from '@/components/settings/companySettings.vue';
import bankAccount from '@/components/settings/bankAccountSettings.vue';
import deleteComponent from '@/components/settings/deleteSettings.vue';
import settingsComponent from '@/components/drawers/settingsDrawer.vue';
import LogService from '@/services/LogService';
import common from '@/mixins/common';
import changeLang from '@/mixins/changeLang';
import { mapState } from 'vuex';
import MergeHelper from '@/helpers/MergeHelper';
import { nanoid } from 'nanoid';
import wpSection from '@/enums/wpSection';
import { additional, settings, commonApp } from '@/enums/stringIndices';
import configEnv from '@/enums/configEnv';

export default {
  mixins: [common, changeLang],
  components: {
    personalComponent,
    companyComponent,
    bankAccount,
    deleteComponent,
    settingsComponent,
  },
  props: {
    nextDisabled: Boolean,
  },
  data() {
    return {
      logoutApi: '/api/user/logout',
      form: {},
      formApi: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        iban: '',
        consentChkbx: true,
        companyName: '',
      },
      str: {
        additional,
        settings,
        commonApp,
      },
      settingsContent: 'personalComponent',
      accordeons: [true],
      opened: 0, /** opened accordeon */
      logoutAccepted: false,
      validated: false,
    };
  },
  computed: {
    ...mapState(['strings', 'breakpoint', 'lang', 'countries', 'loading']),
    ...mapState({
      menu: (state) => state.settings.menu,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // switch to a view according current hash of url
        switch (to.hash) {
        case '#bank':
          this.settingsContent = 'bankAccount';
          break;
        case '#company':
          this.settingsContent = 'companyComponent';
          break;
        default:
          this.settingsContent = 'personalComponent';
          break;
        }
      },
    },
    formApi(newFormApi) {
      LogService.log('formApi watcher', newFormApi);
    },
    validated: {
      immediate: true,
      handler(v) {
        LogService.log('watch validated, nextDisabled:', !v);
        this.$emit('update:nextDisabled', !v);
      },
    },
  },
  created() {
    this.init();
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
  methods: {
    async init() {
      await Promise.all([
        this.getWordPressStrings(
          [
            wpSection.SETTINGS,
            wpSection.COMMON_APP,
            wpSection.ADDITIONAL,
          ],
        ),
        this.apiUserInfo(),
        this.$root.$on('buttonNext', this.next),
        this.$root.$on('buttonBack', this.back),
      ]);
      await this.$emit('update:nextTitle', this.str.settings.save_settings);
      await this.setMenu();
    },
    updateForm(form) {
      LogService.log('updateForm start: ', this.formApi, form);
      this.form = MergeHelper.mergeObjects(form, this.formApi, true);
      LogService.log('updateForm: ', this.form);
    },
    modalDelete() {
      // open modal delete account
      const message = 'Do you really want to delete your profile?';
      const modal = {
        id: 'wet-settings-error-modal',
        title: 'Delete Profile',
        icon: 'error',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            title: this.str.commonApp?.ok,
            name: 'ok-error-delete',
            event: ['close', this.deleteAccount],
          },
          {
            title: this.str.commonApp?.cancel,
            name: 'ok-error-cancel',
            event: 'close',
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
    deleteAccount() {
      LogService.log('delete account');
    },
    logout() {
      // logout user
      if (!this.logoutAccepted) {
        // show popup are you shure?
        const message = this.str.commonApp?.logout_message;
        const modal = {
          id: 'wet-settings-logout-modal',
          title: this.str.commonApp?.logout,
          icon: 'error',
          content: `<div class="text-center">${message}</div>`,
          actions: [
            {
              title: this.str.commonApp?.yes,
              name: 'ok-logout',
              event: [
                'close',
                () => {
                  this.logoutAccepted = true;
                },
                this.logout,
              ],
            },
            {
              title: this.str.commonApp?.no,
              name: 'cancel-logout',
              event: 'close',
            },
          ],
        };
        this.$store.dispatch('addModal', modal);
        return;
      }
      this.callApi(this.logoutApi)
        .then(() => {
          this.$router.push({
            name: configEnv.login_success_redirect_page,
            params: { ...this.$route.params },
          });
        })
        .catch(() => {
          LogService.log('already logouted');
        })
        .finally(() => {
          this.logoutAccepted = false;
        });
    },
    toggle(i) {
      // toggle opeded accordeon by ID
      this.opened = this.opened === i ? null : i;
    },
    async apiUserInfo() {
      // call User Info API return Promise
      const res = await this.$webAppAPI.getUserInfoUserOnly()
        .catch(() => this.redirectToLogin());
      LogService.log('got user data', res.data);
      this.prefillInputs(res.data);
      if (this.formApi.status === undefined || !['0', '1'].includes(this.formApi.status?.toString())) {
        this.redirectToLogin();
      }
    },
    redirectToLogin() {
      this.$router.replace(
        {
          name: 'login',
          params: { ...this.$route.params },
          query: { redirect: 'settings' },
        },
      );
    },
    prefillInputs(apiData) {
      // prefill inputs from User info API data
      this.formApi = {
        consentChkbx: true,
        firstname: apiData.profile?.firstname,
        lastname: apiData.profile?.lastname,
        phone: apiData.profile?.phone,
        phoneCode: apiData.profile?.phone_code || this.phoneCodes[0].code,
        companyName: apiData.profile?.company,
        iban: apiData.payment?.iban?.toString(),
        email: apiData.user?.email,
        status: apiData?.user?.status?.toString(),
      };

      LogService.log('prefilled data', this.formApi);

      // set UsetStatus
      this.$store.commit('SET_USER_STATUS', this.form.status);
    },
    async updateUser() {
      const loadingId = nanoid();
      LogService.log('creating screen, loadingId', this.loadingId);
      this.$store.commit('SET_LOADING', loadingId);
      const formApi = this.$lodash.cloneDeep(this.form);
      LogService.log('deep copy', formApi);

      const data = {};
      // get changed properties
      const keys = [];
      Object.keys(formApi).forEach((key) => {
        if (!this.$lodash.isEqual(formApi[key], this.formApi[key])) {
          keys.push(key);
        }
      });
      LogService.log('changed keys', keys);
      // return in error
      if (keys.length === 0) {
        this.setMenu();
        this.$store.commit('RESET_LOADING');
        return;
      }

      const profileChange = keys.some((prop) => [
        'firstname',
        'lastname',
        'phone',
        'phoneCode',
        'companyName',
      ].includes(prop));

      LogService.log('any profile changes', profileChange);

      // check changes
      if (profileChange) {
        LogService.log('data profile changed', data);
        data.profile = {
          firstname: formApi.firstname,
          lastname: formApi.lastname,
          phone: formApi.phone,
          phone_code: formApi.phoneCode,
          company: formApi.companyName,
        };
      }
      if (keys.some((prop) => ['registration'].includes(prop))) {
        data.registration_address = {
          address_line_1: formApi.registration.street,
          address_line_2: formApi.registration.house,
          address_line_zip: formApi.registration.zip,
          address_line_city: formApi.registration.city,
          country_code: formApi.registration.country,
        };
      }
      if (keys.some((prop) => ['iban'].includes(prop))) {
        data.payment = {
          iban: formApi.iban,
        };
      }

      LogService.log('update user data', data, formApi);
      await this.$webAppAPI.putSettings(data).then((res) => {
        if (res.status === 200) this.formApi = formApi;
        LogService.log('this.formApi after update', formApi);
      });

      this.setMenu();
      this.$store.commit('DEL_LOADING', loadingId);
    },
    setMenu() {
      LogService.log('start to set menu');
      const personal = this.formApi.firstname && this.formApi.lastname && `${this.formApi.firstname} ${this.formApi.lastname}`;
      // const iban = this.formApi.iban && `************${this.formApi.iban.slice(-4)}`;
      const menu = [{
        title: this.str.settings?.menu_personal,
        icon: 'personal',
        subtitle: personal || this.str.settings?.menu_personal_sub,
        component: 'personalComponent',
        href: {
          name: 'settings',
          params: { ...this.$route.params },
        },
        exact: true,
      }];
      // no not show company name and registration address currently.
      // This component needs refactoring, but is almost never used
      // if (this.formApi.companyName) {
      //   menu.push({
      //     title: this.str.settings?.menu_company,
      //     icon: 'company',
      //     subtitle: this.formApi.companyName || this.str.settings?.menu_company_sub,
      //     component: 'companyComponent',
      //     href: '#company',
      //   });
      // }

      menu.push(
      //   {
      //   title: this.str.settings?.menu_bank,
      //   icon: 'bank',
      //   subtitle: iban || this.str.settings?.menu_bank_sub,
      //   component: 'bankAccount',
      //   href: '#bank',
      // },
      // {
      //   title: 'Delete profile',••••••••••••••••••
      //   event: this.modalDelete,
      // },
        {
          title: this.str.commonApp.logout,
          event: this.logout,
        },
      );
      this.$store.commit('settings/SET_MENU', menu);
    },
    back() {
      // this.$router.push({ name: 'home', params: { ...this.$route.params } });
      window.location = `/home/${this.$route.params?.lang || ''}`;
    },
    next() {
      this.updateUser();
    },
  },
};
</script>
